import * as types from '../actions/types';

const initialState = {
    resumes: [],
    workexperiences: [],
    educations: [],
    skills:[],
    contacts:[],
    projects: [],
    blogs: [],
    loading: false
}

export default function(state = initialState, action){
    switch(action.type) {
        //resumes
        case types.GET_RESUME:
            return {
                ...state,
                resumes: action.payload,
                loading: false
            };
        case types.DELETE_RESUME:
            return {
                ...state,
                resumes: state.resumes.filter(resume => resume._id !== action.payload)
            };
        case types.ADD_RESUME:
            return {
                ...state,
                resumes: [action.payload, ...state.resumes]
            };
        //experiences
        case types.GET_WORKEXPERIENCE:
            return {
                ...state,
                workexperiences: action.payload,
                loading: false
            };
        case types.DELETE_WORKEXPERIENCE:
            return {
                ...state,
                workexperiences: state.workexperiences.filter(workexperience => workexperience._id !== action.payload)
            };
        case types.ADD_WORKEXPERIENCE:
            return {
                ...state,
                workexperiences: [action.payload, ...state.workexperiences]
            };
        //educations
        case types.GET_INSTITUTE_NAME:
            return {
                ...state,
                educations: action.payload,
                loading: false
            };
        case types.DELETE_INSTITUTE_NAME:
            return {
                ...state,
                educations: state.educations.filter(education => education._id !== action.payload)
            };
        case types.ADD_INSTITUTE_NAME:
            return {
                ...state,
                educations: [action.payload, ...state.educations]
            };
        //skills
        case types.GET_SKILLS:
            return {
                ...state,
                skills: action.payload,
                loading: false
            };
        case types.DELETE_SKILLS:
            return {
                ...state,
                skills: state.skills.filter(skill => skill._id !== action.payload)
            };
        case types.ADD_SKILLS:
            return {
                ...state,
                skills: [action.payload, ...state.skills]
            };
        //contact
        case types.GET_CONTACT:
            return {
                ...state,
                contacts: action.payload,
                loading: false
            };
        case types.DELETE_CONTACT:
            return {
                ...state,
                contacts: state.contacts.filter(contact => contact._id !== action.payload)
            };
        case types.ADD_CONTACT:
            return {
                ...state,
                contacts: [action.payload, ...state.contacts]
            };
        //projects
        case types.GET_PROJECT:
            return {
                ...state,
                projects: action.payload,
                loading: false
            };
        case types.DELETE_PROJECT:
            return {
                ...state,
                projects: state.projects.filter(project => project._id !== action.payload)
            };
        case types.ADD_PROJECT:
            return {
                ...state,
                projects: [action.payload, ...state.projects]
            };

        //blogs
        case types.GET_BLOG:
            return {
                ...state,
                blogs: action.payload,
                loading: false
            };
        case types.EDIT_BLOG:
            return {
                ...state,
                blogs: action.payload,
                loading: false
            };
        case types.DELETE_BLOG:
            return {
                ...state,
                blogs: state.blogs.filter(blog => blog._id !== action.payload)
            };
        case types.ADD_BLOG:
            return {
                ...state,
                blogs: [action.payload, ...state.blogs]
            };
        case types.UPDATE_BLOG:
            return {
                ...state,
                updatedBlog: action.payload, 
                loading: false
            };
            
    
        case types.RESUMES_LOADING:
            return {
                ...state,
                loading: true
            };

        default:
            return state;

    }
}