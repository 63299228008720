import React, {Component} from 'react';
import {Grid, Cell} from 'react-mdl';
import { Container} from 'reactstrap';
import FooterBody from "./Footer";
class Error extends Component{
	render(){
		return(
			<Container>
				<Grid>
					<Cell col={12} className="error-page">
						<img src="https://res.cloudinary.com/tammycloudinary/image/upload/v1588034478/page-not-found-1907792_640.jpg" alt="error"/>
					</Cell>
				</Grid>
				<FooterBody/>
			</Container>
		)
	}
}
export default Error;