import React, {Component} from 'react';
import { Container } from "reactstrap";
import { Grid, Cell } from "react-mdl";
class Loading extends Component{

    render(){
        return (
           <Container>
                <Grid>
                    <Cell col={12} >
                    <div className="text-center mt-5 loading-container" >
                        <div className="spinner-grow text-primary loading-container-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-secondary loading-container-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-success loading-container-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-danger loading-container-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-warning loading-container-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-info loading-container-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-success loading-container-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-dark loading-container-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <div className="spinner-grow text-info loading-container-spinner" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    </Cell>
                </Grid>
            </Container>
        )
    }
}

export default Loading;