import React, {Component} from 'react';
import ReactGA from 'react-ga';
import { Container, 
    Button, Modal,  ModalHeader, ModalBody,
     Form, FormGroup, Label, Input, Row, Col} from 'reactstrap';
import { connect } from 'react-redux';
import { addResume ,addWorkexperience, addInstitutename, addSkill, addContact, addProject} from '../../actions/resumeActions';
import PropTypes from 'prop-types';


class Dashboard extends Component{

    initializeReactGA() {
	    ReactGA.initialize('UA-132348738-1');
	    ReactGA.pageview('/');
    }

    constructor(props) {
        super(props);
        this.state = {
            modal1: false,
            modal2: false,
            modal3: false,
            modal4: false,
            modal5: false,
            modal6: false,
            resume_user_name:'',
            resume_about: '',
            resume_contact_number: '',
            resume_address: '',
            resume_email: '',
            resume_image_link: '',
            company_name: '',
            work_designation: '',
            work_note: '',
            work_start_date: '',
            work_end_date: '',
            institute_name: '',
            stream_branch: '',
            education_start_date: '',
            education_end_date: '',
            skill_name: '',
            skill_progress: '',
            skill_buffer: '',
            contact_name: '',
            contact_phone: '',
            contact_email: '',
            contact_skype: '',
            contact_image: '',
            project_type:'',
            project_name: '',
            project_description: '',
            project_link: '',
            project_image_link:''
        };
    
        this.handleSubmit1 = this.handleSubmit1.bind(this);
        this.handleSubmit2 = this.handleSubmit2.bind(this);
        this.handleSubmit3 = this.handleSubmit3.bind(this);
        this.handleSubmit4 = this.handleSubmit4.bind(this);
        this.handleSubmit5 = this.handleSubmit5.bind(this);
        this.handleSubmit6 = this.handleSubmit6.bind(this);
        
        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
        this.toggle3 = this.toggle3.bind(this);
        this.toggle4 = this.toggle4.bind(this);
        this.toggle5 = this.toggle5.bind(this);
        this.toggle6 = this.toggle6.bind(this);
      
      }
    
    static propTypes = {
        resume: PropTypes.object.isRequired,
        workexperience: PropTypes.object.isRequired,
        education: PropTypes.object.isRequired,
        contact: PropTypes.object.isRequired,
        isAuthenticated: PropTypes.bool,
        auth: PropTypes.object.isRequired
    }

    toggle1 = () => {
        this.setState({
            modal1: !this.state.modal1
        });
    }

    toggle2 = () => {
        this.setState({
            modal2: !this.state.modal2
        });
    }
    toggle3 = () => {
        this.setState({
            modal3: !this.state.modal3
        });
    }
    toggle4 = () => {
        this.setState({
            modal4: !this.state.modal4
        });
    }
    toggle5 = () => {
        this.setState({
            modal5: !this.state.modal5
        });
    }
    toggle6 = () => {
        this.setState({
            modal6: !this.state.modal6
        });
    }

    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value });
    }

    selectProject = (e) => {
        this.setState({ [e.selectedIndex]: e.options.value });
        
    }

    handleSubmit1 = (e) => {
        
        e.preventDefault();
        const newResume = {
            resume_user_name: this.state.resume_user_name,
            resume_about: this.state.resume_about,
            resume_contact_number: this.state.resume_contact_number,
            resume_address: this.state.resume_address,
            resume_email: this.state.resume_email,
            resume_image_link: this.state.resume_image_link

        }
        
        //Add item via addItem action
        this.props.addResume(newResume);

        //close modal
        this.toggle1();
    }

    handleSubmit2 = (e) => {
        
        e.preventDefault();
        const newWorkexperience = {
            company_name: this.state.company_name,
            work_designation: this.state.work_designation,
            work_note: this.state.work_note,
            work_start_date: this.state.work_start_date,
            work_end_date: this.state.work_end_date

        }
        
        //Add item via addItem action
        this.props.addWorkexperience(newWorkexperience);

        //close modal
        this.toggle2();
    }

    handleSubmit3 = (e) => {
        
        e.preventDefault();
        const newInstitutename = {
            institute_name: this.state.institute_name,
            stream_branch: this.state.stream_branch,
            education_start_date: this.state.education_start_date,
            education_end_date: this.state.education_end_date

        }
        
        //Add item via addItem action
        this.props.addInstitutename(newInstitutename);

        //close modal
        this.toggle3();
    }

    handleSubmit4 = (e) => {
        
        e.preventDefault();
        const newSkill = {
            skill_name: this.state.skill_name,
            skill_progress: this.state.skill_progress,
            skill_buffer: this.state.skill_buffer

        }
        
        //Add item via addItem action
        this.props.addSkill(newSkill);

        //close modal
        this.toggle4();
    }

    handleSubmit5 = (e) => {
        
        e.preventDefault();
        const newContact = {
            contact_name: this.state.contact_name,
            contact_phone: this.state.contact_phone,
            contact_email: this.state.contact_email,
            contact_skype: this.state.contact_skype,
            contact_image: this.state.contact_image

        }
        
        //Add item via addItem action
        this.props.addContact(newContact);

        //close modal
        this.toggle5();
    }

    handleSubmit6 = (e) => {
        
        e.preventDefault();
        const newProject = {
            project_type: this.state.project_type,
            project_name: this.state.project_name,
            project_description: this.state.project_description,
            project_link: this.state.project_link,
            project_image_link: this.state.project_image_link

        }
        
        //Add item via addProject action
        this.props.addProject(newProject);

        //close modal
        this.toggle6();
    }




    

	render(){


		return(
			<Container className="container-dashboard">
                <Row xs="12" className="container-dashboard-row">
                    <Col style={{fontWeight: "bold"}}>Resume Section</Col>
                    <Col className="dashboard-items">
                        {/* Resume about section */}
                        <Button 
                            color="dark"
                            style={{marginBottom: '2rem'}}
                            onClick={this.toggle1}
                        >
                            Add Details
                        </Button> 
                        <Modal 
                            isOpen = {this.state.modal1}
                            toggle = {this.toggle1}    
                        >
                            <ModalHeader toggle={this.toggle1}>
                                Add to Resume About section 
                            </ModalHeader>
                            <ModalBody>
                                <Form onSubmit ={this.handleSubmit1}>
                                    <FormGroup>
                                        <Label for="userName">Add User Name</Label>
                                        <Input type="text" name="resume_user_name" id="userName" placeholder="add user name"
                                        onChange={this.onChange}/>

                                        <Label for="about">About</Label>
                                        <Input type="text" name="resume_about" id="about" placeholder="add about"
                                        onChange={this.onChange}/>

                                        <Label for="resume_contact_number">Contact number</Label>
                                        <Input type="text" name="resume_contact_number" id="resume_contact_number" placeholder="add contact number"
                                        onChange={this.onChange}/>

                                        <Label for="address">Address</Label>
                                        <Input type="text" name="resume_address" id="address" placeholder="add address"
                                        onChange={this.onChange}/>

                                        <Label for="email">email</Label>
                                        <Input type="email" name="resume_email" id="email" placeholder="add email"
                                        onChange={this.onChange}/>

                                        <Label for="resume_image_link">Resume Image url</Label>
                                        <Input type="url" name="resume_image_link" id="resume_image_link" placeholder="add resume image link"
                                        onChange={this.onChange}/>
                                        <Button
                                            color="dark"
                                            style={{marginTop: '2rem'}}
                                            block
                                        >Add about</Button>
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Col>


                    <Col className="dashboard-items">
                        {/* Resume wprk experience section */}
                        <Button 
                            color="dark"
                            style={{marginBottom: '2rem'}}
                            onClick={this.toggle2}
                        >
                            Add experiece 
                        </Button> 
                        <Modal 
                            isOpen = {this.state.modal2}
                            toggle = {this.toggle2}    
                        >
                            <ModalHeader toggle={this.toggle2}>
                                Add to Resume work experiece section 
                            </ModalHeader>
                            <ModalBody>
                                <Form onSubmit ={this.handleSubmit2}>
                                    <FormGroup>
                                        <Label for="companyName">Company Name</Label>
                                        <Input type="text" name="company_name" id="companyName" placeholder="Add Company name"
                                        onChange={this.onChange}/>

                                        <Label for="designation">Designation</Label>
                                        <Input type="text" name="work_designation" id="designation" placeholder="Work designation"
                                        onChange={this.onChange}/>

                                        <Label for="workDetails">Work details</Label>
                                        <Input type="text" name="work_note" id="workDetails" placeholder="Work details"
                                        onChange={this.onChange}/>

                                        <Label for="startDate">start dateS</Label>
                                        <Input type="date" name="work_start_date" id="startDate" placeholder="add start date"
                                        onChange={this.onChange}/>

                                        <Label for="endDate">End date</Label>
                                        <Input type="date" name="work_end_date" id="endDate" placeholder="add end date"
                                        onChange={this.onChange}/>
                                        <Button
                                            color="dark"
                                            style={{marginTop: '2rem'}}
                                            block
                                        >Add work experiece</Button>
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Col>

                    <Col className="dashboard-items">
                        {/* Resume education  section */}
                        <Button 
                            color="dark"
                            style={{marginBottom: '2rem'}}
                            onClick={this.toggle3}
                        >
                            Add Education
                        </Button> 
                        <Modal 
                            isOpen = {this.state.modal3}
                            toggle = {this.toggle3}    
                        >
                            <ModalHeader toggle={this.toggle3}>
                                Add to Resume education section 
                            </ModalHeader>
                            <ModalBody>
                                <Form onSubmit ={this.handleSubmit3}>
                                    <FormGroup>
                                        <Label for="instituteName">Institute Name</Label>
                                        <Input type="text" name="institute_name" id="instituteName" placeholder="Add Institue name"
                                        onChange={this.onChange}/>

                                        <Label for="streamName">Branch/stream Name</Label>
                                        <Input type="text" name="stream_branch" id="streamName" placeholder="Add Branch name"
                                        onChange={this.onChange}/>

                                        <Label for="startDate">start date</Label>
                                        <Input type="date" name="education_start_date" id="startDate" placeholder="add start date"
                                        onChange={this.onChange}/>

                                        <Label for="endDate">End date</Label>
                                        <Input type="date" name="education_end_date" id="endDate" placeholder="add end date"
                                        onChange={this.onChange}/>
                                        <Button
                                            color="dark"
                                            style={{marginTop: '2rem'}}
                                            block
                                        >Add Institute</Button>
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Col>

                    <Col className="dashboard-items">
                        {/* Resume education  section */}
                        <Button 
                            color="dark"
                            style={{marginBottom: '2rem'}}
                            onClick={this.toggle4}
                        >
                            Add Skills
                        </Button> 
                        <Modal 
                            isOpen = {this.state.modal4}
                            toggle = {this.toggle4}    
                        >
                            <ModalHeader toggle={this.toggle4}>
                                Add to Resume skills section 
                            </ModalHeader>
                            <ModalBody>
                                <Form onSubmit ={this.handleSubmit4}>
                                    <FormGroup>
                                        <Label for="skillName">Skill Name</Label>
                                        <Input type="text" name="skill_name" id="skillName" placeholder="Add Skill name"
                                        onChange={this.onChange}/>

                                        <Label for="skillProgress">Pregress %</Label>
                                        <Input type="text" name="skill_progress" id="skillProgress" placeholder="Add Progress %"
                                        onChange={this.onChange}/>

                                        <Label for="skillBuffer">Buffer %</Label>
                                        <Input type="text" name="skill_buffer" id="skillBuffer" placeholder="add buffer %"
                                        onChange={this.onChange}/>

                                        <Button
                                            color="dark"
                                            style={{marginTop: '2rem'}}
                                            block
                                        >Add Skill</Button>
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Col>

                    
                </Row>
                <Row xs="12" className="container-dashboard-row">
                    <Col style={{fontWeight: "bold"}}>Contact Section</Col>
                    <Col className="dashboard-items">
                        {/* Resume about section */}
                        <Button 
                            color="dark"
                            style={{marginBottom: '2rem'}}
                            onClick={this.toggle5}
                        >
                            Add contact
                        </Button> 
                        <Modal 
                            isOpen = {this.state.modal5}
                            toggle = {this.toggle5}    
                        >
                            <ModalHeader toggle={this.toggle5}>
                                Add to contact section 
                            </ModalHeader>
                            <ModalBody>
                                <Form onSubmit ={this.handleSubmit5}>
                                    <FormGroup>
                                        <Label for="contactName">Add Contact Name</Label>
                                        
                                        <Input type="text" name="contact_name" id="contactName" placeholder="add contact name"
                                        onChange={this.onChange}/>

                                        <Label for="contactNumber">Contact number</Label>
                                        <Input type="text" name="contact_phone" id="contactNumber" placeholder="add contact number"
                                        onChange={this.onChange}/>

                                        <Label for="contactEmail">contact email</Label>
                                        <Input type="email" name="contact_email" id="contactEmail" placeholder="add contact email"
                                        onChange={this.onChange}/>

                                        <Label for="contactSkype">Contact Skype</Label>
                                        <Input type="text" name="contact_skype" id="contactSkype" placeholder="add contact skype"
                                        onChange={this.onChange}/>

                                        <Label for="contactImage">Contact Image url</Label>
                                        <Input type="url" name="contact_image" id="contactImage" placeholder="add Contact image link"
                                        onChange={this.onChange}/>
                                        <Button
                                            color="dark"
                                            style={{marginTop: '2rem'}}
                                            block
                                        >Add contact</Button>
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Col>

                    <Col className="dashboard-items">
                        {/* Resume about section */}
                        <Button 
                            color="dark"
                            style={{marginBottom: '2rem'}}
                            onClick={this.toggle6}
                        >
                            Add Project
                        </Button> 
                        <Modal 
                            isOpen = {this.state.modal6}
                            toggle = {this.toggle6}    
                        >
                            <ModalHeader toggle={this.toggle6}>
                                Add to Project section 
                            </ModalHeader>
                            <ModalBody>
                                <Form onSubmit ={this.handleSubmit6}>
                                    <FormGroup>
                                        <Label for="projectType">Select Project type</Label>
                                        <select id="projectType" name="project_type" onChange={(e) => this.setState({project_type: e.target.value})}>
                                        <option >----Select---</option>
                                        <option value="Reactjs">Reactjs</option>
                                        <option value="Html">HTML</option>
                                        </select><br/>

                                        <Label for="projectName">Project name</Label>
                                        <Input type="text" name="project_name" id="projectName" placeholder="add Project name"
                                        onChange={this.onChange}/>

                                        <Label for="projectDescription">Project description</Label>
                                        <Input type="text" name="project_description" id="projectDescription" placeholder="add project description"
                                        onChange={this.onChange}/>

                                        <Label for="projectLink">Project Link</Label>
                                        <Input type="url" name="project_link" id="projectLink" placeholder="add project link"
                                        onChange={this.onChange}/>

                                        <Label for="projectImageUrl">Project Image url</Label>
                                        <Input type="url" name="project_image_link" id="projectImageUrl" placeholder="add project image link"
                                        onChange={this.onChange}/>
                                        <Button
                                            color="dark"
                                            style={{marginTop: '2rem'}}
                                            block
                                        >Add project</Button>
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                        </Modal>
                    </Col>
                    </Row>
            </Container>
		)
	}
}

const mapStateToProps = (state) => ({
    resume: state.resume,
    workexperience: state.workexperience,
    education: state.education,
    contact: state.contact,
    skill: state.skill,
    project: state.project,
    isAuthenticated : state.auth.isAuthenticated,
    auth: state.auth
});

export default connect(mapStateToProps, { addResume, addWorkexperience, addInstitutename, addSkill, addContact, addProject }) (Dashboard);

