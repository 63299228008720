import React, {Component} from 'react';
import {Grid, Cell} from 'react-mdl';
import { Container } from "reactstrap";
import FooterBody from "./Footer";
import SocialLinks from "./SocialLinks";
class Homepage extends Component{
	render(){
		return(
			<Container>
			<Grid className ="landing-grid" >
				<Cell col ={12}>
					<img
						src="https://res.cloudinary.com/tammycloudinary/image/upload/v1547240801/tammy.png"
						alt="avatar" className="avatar-img"
					/>
					<div className="banner-text">
						<h1 className="h1-homepage-heading">Frontend ReactJs Developer</h1>
						<hr/>
						<p className="homepage-languages"> HTML5 | CSS3 | Bootstrap | JavaScript | ReactJs | Redux | WebRTC </p>
						<SocialLinks/>
					</div>
				</Cell>
			</Grid>
			<FooterBody/>
			</Container>
		)
	}
}

export default Homepage;