import React , {Component} from 'react';
import { Container } from "reactstrap";
import { Grid } from "react-mdl";
class SocialLinks extends Component {
	render(){
		return(
            <Container>
                <Grid>
                    <div className="social-links"> 
                        {/* Linkedin */}
                        <a href="https://www.linkedin.com/in/tammysarkar/" target="_blank" aria-hidden="true" rel="noopener noreferrer"> 
                            <i className="fa fa-linkedin-square" />
                        </a>
                        {/* Instagram */}
                        <a href="https://www.instagram.com/tammysarkar/" target="_blank" aria-hidden="true" rel="noopener noreferrer"> 
                            <i className="fa fa-instagram" />
                        </a>
                        {/* Github */}
                        <a href="https://github.com/tanmoysarkar" target="_blank" aria-hidden="true" rel="noopener noreferrer"> 
                            <i className="fa fa-github-square" />
                        </a>
                        {/* Facebook */}
                        <a href="https://www.facebook.com/sarkartammy" target="_blank" aria-hidden="true" rel="noopener noreferrer"> 
                            <i className="fa fa-facebook-square" />
                        </a>
                        {/* Twitter */}
                        {/* <a href="https://twitter.com/tammysarkar" target="_blank" aria-hidden="true" rel="noopener noreferrer"> 
                            <i className="fa fa-twitter-square" />
                        </a> */}
                    </div>
                </Grid>
            </Container>
			
		)
	}
}

export default SocialLinks;