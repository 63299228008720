import React, { Component } from 'react';
import { Layout, Header, Navigation, Drawer, Content } from 'react-mdl';
import Main from '../Main';
import {Link} from 'react-router-dom';
import { Container } from "reactstrap";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MenuLogin from './MenuLogin';

class LandingPage extends Component {

    static propTypes = {
        auth: PropTypes.object.isRequired
    }
    
    render(){
        return(
            
            <Container className="demo-big-content">
                <Layout>
                    <Header className="header-color" title={<Link style={{textDecoration: 'none', color: 'white'}} to="/">Tanmoy Sarkar</Link>} scroll>
                        
                        <Navigation >
                            <Link to="/#">Home</Link>
                            <Link to="/about">About Me</Link>
                            <Link to="/projects">Projects</Link>
                            <Link to="/resume">Resume</Link>
                            <Link to="/blogs">Blogs</Link>
                            <Link to="/contact">Contact</Link>
                            
                            
                            <MenuLogin/>
                        </Navigation>
                    </Header>
                    <Drawer style={{fontWeight: "bold"}} title="Tanmoy Sarkar">
                        <Navigation className="homepage-navigation-border">
                            
                            <Link to="/#">Home</Link>
                            <Link to="/about">About Me</Link>
                            <Link to="/projects">Projects</Link>
                            <Link to="/resume">Resume</Link>
                            <Link to="/blogs">Blogs</Link>
                            <Link to="/contact">Contact</Link>
                            <MenuLogin/>
                        </Navigation>
                    </Drawer>
                    <Content>
                       
                    <Main/>   
                    
                    </Content>
                </Layout>
            </Container>
            
        )
    }

}
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(LandingPage);
