import React, { Component } from 'react';
import { Button, Modal,  ModalHeader, ModalBody, Form, FormGroup, Label, Input} from 'reactstrap';
import {Grid, Cell} from 'react-mdl';
import { connect } from 'react-redux';
import { addBlog} from '../../actions/resumeActions';
import PropTypes from 'prop-types';

class BlogModal extends Component {
    state = {
        modal: false,
        blog_short_desc: '',
        blog_name: '',
        blog_desc_head1: '',
        blog_desc_text1: '',
        blog_desc_head2: '',
        blog_desc_text2: '',
        blog_desc_head3: '',
        blog_desc_text3: '',
        blog_desc_head4: '',
        blog_desc_text4: '',
        blog_desc_head5: '',
        blog_desc_text5: '',
        blog_image_link: '',
        blog_by: '',
        blog_by_author: ''

    }

    static propTypes = {
        auth: PropTypes.object.isRequired
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value });
    }

    onSubmit = (e) => {
        e.preventDefault();
        const newBlog = {
            blog_short_desc: this.state.blog_short_desc,
            blog_name: this.state.blog_name,
            blog_desc_head1: this.state.blog_desc_head1,
            blog_desc_text1: this.state.blog_desc_text1,
            blog_desc_head2: this.state.blog_desc_head2,
            blog_desc_text2: this.state.blog_desc_text2,
            blog_desc_head3: this.state.blog_desc_head3,
            blog_desc_text3: this.state.blog_desc_text3,
            blog_desc_head4: this.state.blog_desc_head4,
            blog_desc_text4: this.state.blog_desc_text4,
            blog_desc_head5: this.state.blog_desc_head5,
            blog_desc_text5: this.state.blog_desc_text5,
            blog_image_link: this.state.blog_image_link,
            blog_by:  this.props.auth["user"]._id,
            blog_by_author: this.props.auth["user"].name
        }
        
        //Add item via addItem action
        this.props.addBlog(newBlog);
        alert("Blog added successfully!");
        //close modal
        this.toggle();
    }
    render(){
        const { isAuthenticated } = this.props.auth;
       
        return(
            <div>
                { isAuthenticated ? 
                <Grid >
                    <Cell col={12}>
                        <Button 
                            color="dark"
                            style={{marginBottom: '2rem'}}
                            onClick={this.toggle}
                            className="m-3"
                        >
                            Add Blog
                        </Button> 
                    </Cell> 
                </Grid> : "" }
                <Modal 
                    isOpen = {this.state.modal}
                    toggle = {this.toggle}    
                >
                    <ModalHeader toggle={this.toggle}>
                        Add to blog List
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit ={this.onSubmit }>
                            <FormGroup>
                                <Label for="blogHeading">Blog Heading</Label>
                                <Input type="text" name="blog_short_desc" id="blogHeading" placeholder="Add one liner"
                                onChange={this.onChange} required/>
                                 <Label for="blogName">Blog Name</Label>
                                <Input type="text" name="blog_name" id="blogName" placeholder="Add blog name"
                                onChange={this.onChange} required/>

                                <Label for="descHead1">Description Heading 1</Label>
                                <Input type="text" name="blog_desc_head1" id="descHead1" placeholder="Add your blog heading"
                                onChange={this.onChange} required/>

                                <Label for="descText1">Description Text 1 </Label>
                                <Input type="textarea" name="blog_desc_text1" id="descText1" placeholder="Add your blog text"
                                onChange={this.onChange} required/>

                                <Label for="descHead2">Description Heading 2</Label>
                                <Input type="text" name="blog_desc_head2" id="descHead2" placeholder="Add your blog heading"
                                onChange={this.onChange} required/>
                                <Label for="descText2">Description Text 2 </Label>
                                <Input type="textarea" name="blog_desc_text2" id="descText2" placeholder="Add your blog text"
                                onChange={this.onChange} required/>

                                <Label for="descHead3">Description Heading 3</Label>
                                <Input type="text" name="blog_desc_head3" id="descHead3" placeholder="Add your blog heading"
                                onChange={this.onChange} required/>
                                <Label for="descText3">Description Text 3 </Label>
                                <Input type="textarea" name="blog_desc_text3" id="descText3" placeholder="Add your blog text"
                                onChange={this.onChange} required/>

                                <Label for="descHead4">Description Heading 4</Label>
                                <Input type="text" name="blog_desc_head4" id="descHead4" placeholder="Add your blog heading (Optional)"
                                onChange={this.onChange} />
                                <Label for="descText4">Description Text 4 </Label>
                                <Input type="textarea" name="blog_desc_text4" id="descText4" placeholder="Add your blog text (Optional)"
                                onChange={this.onChange} />

                                <Label for="descHead5">Description Heading 5</Label>
                                <Input type="text" name="blog_desc_head5" id="descHead5" placeholder="Add your blog heading (Optional)"
                                onChange={this.onChange} />
                                <Label for="descText5">Description Text 5 </Label>
                                <Input type="textarea" name="blog_desc_text5" id="descText5" placeholder="Add your blog text (Optional)"
                                onChange={this.onChange} />

                                <Label for="imageUrl">Image Url</Label>
                                <Input type="text" name="blog_image_link" id="imageUrl" placeholder="Add image url (Optional)"
                                onChange={this.onChange}/>
                                

                                <Button
                                    color="dark"
                                    style={{marginTop: '2rem'}}
                                    block
                                >Add blog</Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    resume: state.resume,
    auth: state.auth
})

export default connect(mapStateToProps, { addBlog })(BlogModal);