import React , {Component} from 'react';
import { Footer, FooterSection, FooterLinkList  } from 'react-mdl';
class FooterBody extends Component {
	render(){
		return(
			<Footer size="mini">
				<FooterSection type="left" className="footer-section-type-left">
					<FooterLinkList>
					&copy; 2021 Designed & Developed by Tanmoy Sarkar 
					</FooterLinkList>
				</FooterSection>
			</Footer>
		)
	}
}

export default FooterBody;