import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Homepage from './user/Homepage';
import Aboutme from './user/AboutMe';
import Error from './user/Error';
import Dashboard from './admin/Dashboard';
import Resume from './user/Resume';
import Contact from './user/Contact';
import Projects from './user/Projects';
import Blogs from './user/Blogs';
import GoogleAnalytics from './GoogleAnalytics';
class Main extends Component {
    static propTypes = {
        auth: PropTypes.object.isRequired,
        loading: PropTypes.object.isRequired
    }


    render(){
        const { isAuthenticated, user } = this.props.auth;

        return(
            <Switch>
            <Route  exact path="/" component={Homepage}/>
            <Route path="/about" component={Aboutme}/>
			<Route path="/resume" component={Resume}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/projects" component={Projects}/>
            
            <Route path="/blogs" component={Blogs}/>
           
            { user ? (user.is_admin === true && isAuthenticated ? 
           ( <Route path="/dashboard" component={Dashboard}/> ) : ( <Route component={Error}/>) ):  <Route component={Error}/> }
            <GoogleAnalytics/>
            </Switch>

        )
    }

}

const mapStateToProps = state => ({
auth: state.auth,
loading: state.apiCallsInProgress > 0
});

export default connect(mapStateToProps, null)(Main);
