import React from 'react';
import { Button, Modal,  ModalHeader, ModalBody, Form, FormGroup, Label, Input} from 'reactstrap';
import { connect } from 'react-redux';
import { updateBlog} from '../../actions/resumeActions';

const EditBlog = ({ toggle, onTodoChange, onSubmit, ...state}) => {
    
    return( 
        <span>
            <Modal 
                isOpen = {state.modal && state.requiredItem === state._id}
                toggle = {()=>this.toggle(state._id)}    
            >
                <ModalHeader toggle={toggle}  style={{fontWeight: "bold"}}>
                    Edit your blog {state.blog_name}
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={e => onSubmit(e, state._id )}>
                        <FormGroup>
                            <Label for="blogHeading">Blog Heading</Label>
                            <Input type="text" name="blog_short_desc" id="blogHeading" placeholder="Update one liner"
                            onChange={onTodoChange} defaultValue={state.blog_short_desc}/>
                            <Label for="blogName">Blog Name</Label>
                            <Input type="text" name="blog_name" id="blogName" placeholder="Update blog name"
                            onChange={onTodoChange} defaultValue={state.blog_name}/>

                            <Label for="descHead1">Description Heading 1</Label>
                            <Input type="text" name="blog_desc_head1" id="descHead1" placeholder="Add your blog heading"
                            onChange={onTodoChange} defaultValue={state.blog_desc_head1}/>

                            <Label for="descText1">Description Text 1 </Label>
                            <Input type="textarea" name="blog_desc_text1" id="descText1" placeholder="Add your blog text"
                            onChange={onTodoChange} defaultValue={state.blog_desc_text1}/>

                            <Label for="descHead2">Description Heading 2</Label>
                            <Input type="text" name="blog_desc_head2" id="descHead2" placeholder="Add your blog heading"
                            onChange={onTodoChange} defaultValue={state.blog_desc_head2}/>
                            <Label for="descText2">Description Text 2 </Label>
                            <Input type="textarea" name="blog_desc_text2" id="descText2" placeholder="Add your blog text"
                            onChange={onTodoChange} defaultValue={state.blog_desc_text2}/>

                            <Label for="descHead3">Description Heading 3</Label>
                            <Input type="text" name="blog_desc_head3" id="descHead3" placeholder="Add your blog heading"
                            onChange={onTodoChange} defaultValue={state.blog_desc_head3}/>
                            <Label for="descText3">Description Text 3 </Label>
                            <Input type="textarea" name="blog_desc_text3" id="descText3" placeholder="Add your blog text"
                            onChange={onTodoChange} defaultValue={state.blog_desc_text3}/>

                            <Label for="descHead4">Description Heading 4</Label>
                            <Input type="text" name="blog_desc_head4" id="descHead4" placeholder="Add your blog heading (Optional)"
                            onChange={onTodoChange} defaultValue={state.blog_desc_head4} />
                            <Label for="descText4">Description Text 4 </Label>
                            <Input type="textarea" name="blog_desc_text4" id="descText4" placeholder="Add your blog text (Optional)"
                            onChange={onTodoChange} defaultValue={state.blog_desc_text4} />

                            <Label for="descHead5">Description Heading 5</Label>
                            <Input type="text" name="blog_desc_head5" id="descHead5" placeholder="Add your blog heading (Optional)"
                            onChange={onTodoChange} defaultValue={state.blog_desc_head5} />
                            <Label for="descText5">Description Text 5 </Label>
                            <Input type="textarea" name="blog_desc_text5" id="descText5" placeholder="Add your blog text (Optional)"
                            onChange={onTodoChange} defaultValue={state.blog_desc_text5} />

                            <Label for="imageUrl">Image Url</Label>
                            <Input type="text" name="blog_image_link" id="imageUrl" placeholder="Update image url (Optional)"
                            onChange={onTodoChange} defaultValue={state.blog_image_link}/>
                            <Button
                                color="dark"
                                style={{marginTop: '2rem'}}
                                block
                            >Edit blog</Button>
                        </FormGroup>
                </Form>
                </ModalBody>
            </Modal>
        </span>
        
    )
}

const mapStateToProps = state => ({
    resume: state.resume,
    auth: state.auth
})

export default connect(mapStateToProps, { updateBlog })(EditBlog);