import React, { Component } from 'react';
import { Button, Modal,  ModalHeader, ModalBody, Form, FormGroup, Label, Input, NavLink, Alert} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';

class LoginModal extends Component {
    state = {
        modal: false,
        email: '',
        password: '',
        is_admin: '',
        msg: null
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        error: PropTypes.object.isRequired,
        login: PropTypes.func.isRequired,
        clearErrors: PropTypes.func.isRequired
        
    }

    componentDidUpdate(prevProps) {
        const { error , isAuthenticated} = this.props;
        if(error !== prevProps.error){
            //check for login error
            if(error.id === 'LOGIN_FAIL') {
                this.setState({msg: error.msg.msg});
            } else { 
                this.setState ({ msg: null});
            }
        }

        //close modal if user loggedin
        if(this.state.modal) {
            if(isAuthenticated) {
                this.toggle();
            }
        }
    }

    toggle = () => {
        //clear errors
        this.props.clearErrors();
        this.setState({
            modal: !this.state.modal
        });
    }

    onChange = (e) => {
        this.setState({ [e.target.name] : e.target.value });
    }

    onClose = () => {
        this.setState({
            open: false
        });
    } 

    onSubmit = (e) => {
        e.preventDefault();
        const { email, password, is_admin  } = this.state;
        const user = {
            email,
            password,
            is_admin
        }

        //attempt to login
        this.props.login(user);

    }
    render(){
        return(
            <div>
                <NavLink onClick={this.toggle} href="#"> Login</NavLink>
                <Modal 
                    isOpen = {this.state.modal}
                    toggle = {this.toggle}    
                >
                    <ModalHeader toggle={this.toggle}>
                        Login 
                    </ModalHeader>
                    <ModalBody>
                     { this.state.msg ? <Alert color="danger">{ this.state.msg}</Alert> : null}
                        <Form onSubmit ={this.onSubmit}>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="email" name="email" id="email" placeholder="Email"
                                className="mb-3"
                                onChange={this.onChange}/>

                                <Label for="password">Password</Label>
                                <Input type="password" name="password" id="password" placeholder="Password"
                                className="mb-3"
                                onChange={this.onChange}/>
                                <Button
                                    color="dark"
                                    style={{marginTop: '2rem'}}
                                    block
                                >Login</Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error
    
})

export default connect(mapStateToProps, { login, clearErrors })(LoginModal);