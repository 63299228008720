export const GET_ITEMS = 'GET_ITEMS';
export const ADD_ITEM = 'ADD_ITEM';
export const DELETE_ITEM = 'DELETE_ITEM';
export const ITEMS_LOADING = 'ITEMS_LOADING';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const BEGIN_API_CALL  = 'BEGIN_API_CALL';
export const API_CALL_ERROR   = 'API_CALL_ERROR';

//resume
export const GET_RESUME = 'GET_RESUME';
export const ADD_RESUME = 'ADD_RESUME';
export const DELETE_RESUME = 'DELETE_RESUME';
export const RESUMES_LOADING = 'RESUMES_LOADING';

//workk experience
export const GET_WORKEXPERIENCE = 'GET_WORKEXPERIENCE';
export const ADD_WORKEXPERIENCE = 'ADD_WORKEXPERIENCE';
export const DELETE_WORKEXPERIENCE = 'DELETE_WORKEXPERIENCE';

//education
export const GET_INSTITUTE_NAME = 'GET_INSTITUTE_NAME';
export const ADD_INSTITUTE_NAME = 'ADD_INSTITUTE_NAME';
export const DELETE_INSTITUTE_NAME = 'DELETE_INSTITUTE_NAME';

//skills
export const GET_SKILLS = 'GET_SKILLS';
export const ADD_SKILLS = 'ADD_SKILLS';
export const DELETE_SKILLS = 'DELETE_SKILLS';

//contacts
export const GET_CONTACT = 'GET_CONTACT';
export const ADD_CONTACT = 'ADD_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';

//projects
export const GET_PROJECT = 'GET_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';


//projects
export const GET_BLOG = 'GET_BLOG';
export const ADD_BLOG = 'ADD_BLOG';
export const EDIT_BLOG = 'EDIT_BLOG';
export const UPDATE_BLOG = 'UPDATE_BLOG';
export const DELETE_BLOG = 'DELETE_BLOG';