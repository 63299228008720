import React, {Component} from 'react';
import {Grid, Cell, ProgressBar} from 'react-mdl';
import Moment from 'moment';
import { Button} from 'reactstrap';
import { Container } from "reactstrap";
import { connect } from 'react-redux';
import { getResume, deleteResume, 
	getWorkexperience, deleteWorkexperiences, 
	getInstitutename, deleteInstitutename,
	getSkill, deleteSkill } from '../../actions/resumeActions';
import PropTypes from 'prop-types';
import Loading from './Loading';
import moment from "moment";
import FooterBody from "./Footer";

class Resume extends Component{
	static propTypes = {
        getResume: PropTypes.func.isRequired,
		deleteResume: PropTypes.func.isRequired,
		getWorkexperience: PropTypes.func.isRequired,
		deleteWorkexperiences: PropTypes.func.isRequired,
		getInstitutename: PropTypes.func.isRequired,
		deleteInstitutename: PropTypes.func.isRequired,
		getSkill: PropTypes.func.isRequired,
        deleteSkill: PropTypes.func.isRequired,
		resume: PropTypes.object.isRequired,
        isAuthenticated: PropTypes.bool,
		auth: PropTypes.object.isRequired,
		loading: PropTypes.object.isRequired
    }

	
    componentDidMount() {
		this.props.getResume();
		this.props.getWorkexperience();
		this.props.getInstitutename();
		this.props.getSkill();
    }

    onDeleteResumeClick = (id) => {
        this.props.deleteResume(id);
	};

	onDeleteExperienceClick = (id) => {
        this.props.deleteWorkexperiences(id);
	};

	onDeleteSkillsClick = (id) => {
        this.props.deleteSkill(id);
	};

	onDeleteEducationsClick = (id) => {
        this.props.deleteInstitutename(id);
	};
	

	render(){
		const { resumes, workexperiences, educations, skills, loading } = this.props.resume;
		const { user } = this.props.auth;
		Moment.locale('en');

		return(
			<Container>
			{loading ? (
	   		<div><Loading/></div>) : 
	   		(<div>
			   <Grid>
				<Cell col={4}>
					{resumes.map(({ _id, resume_user_name, resume_about, resume_contact_number, resume_address, resume_email, resume_image_link }) => (
					<div key={_id} timeout={100} classnames="fade">
						<div>
							{ this.props.isAuthenticated && (user.is_admin === true) ? 
							<Button className="remove-btn"
							color="danger"
							size="sm"
							onClick= {this.onDeleteResumeClick.bind(this, _id)}>
								&times;
							</Button> : null }

							<div style={{textAlign: 'center'}}>
								<img src={resume_image_link}
									alt="avatar"
									style={{height: '220px'}}
								/>
							</div>

							<h2 >{resume_user_name}</h2>
							<h4 style={{color: 'grey', fontWeight:"bold"}}>Software Developer </h4>
							<hr className="resume-left-section-border"  />
							<p >{resume_about}</p>
							<hr className="resume-left-section-border" />
							<h5 style={{textDecoration:"underline" , fontWeight:"bold"}}>Address</h5>
							<p>{resume_address}</p>
							<h5 style={{textDecoration:"underline" , fontWeight:"bold"}}>Phone</h5>
							<p>{resume_contact_number}</p>
							<h5 style={{textDecoration:"underline" , fontWeight:"bold"}}>Email</h5>
							<p>{resume_email}</p>
						</div>
					</div>
					))}
					<hr  className="resume-left-section-border" />

				</Cell>
				<Cell col={8} className="resume-right-col">
					<h2 >Experience</h2>
					{workexperiences.map(({ _id, company_name, work_designation, work_note, work_start_date, work_end_date}) => (
					<Grid key={_id} timeout={100} classnames="fade">
						{ this.props.isAuthenticated && (user.is_admin === true) ? 
						<Button className="remove-btn"
						color="danger"
						size="sm"
						onClick= {this.onDeleteExperienceClick.bind(this, _id)}>
							&times;
						</Button> : null }
						<Cell col={5}>
							<p>
								{Moment(work_start_date).format('MMMM YYYY ')} - {
									(Moment(work_end_date).format('MMMM YYYY ') ? Moment(work_end_date).format('MMMM YYYY ')  === "Invalid date" ? "Present" : Moment(work_end_date).format('MMMM YYYY ')  : moment().format('MMMM YYYY ') )
								}
							</p>
						</Cell>
						
						<Cell col={7}>
							<h4 style={{marginTop:'0px'}}>{company_name}</h4>
							<p>{work_designation}</p>
							<p>{work_note}</p>
						</Cell>
					</Grid>
					
					))}
					<hr className="resume-right-section-border"  />
					<h2>Education</h2>
					{educations.map(({ _id, institute_name, stream_branch, education_start_date, education_end_date}) => (
					<Grid  key={_id} timeout={100} classnames="fade">
						{ this.props.isAuthenticated && (user.is_admin === true) ? 
						<Button className="remove-btn"
							color="danger"
							size="sm"
							onClick= {this.onDeleteEducationsClick.bind(this, _id)}>
								&times;
							</Button> : null }
						<Cell col={5}>
						
							<p>{Moment(education_start_date).format('MMMM YYYY ')} - {Moment(education_end_date).format('MMMM YYYY ')}</p>
						</Cell>
						<Cell col={7}>
							<h4 className="h4-resume-institute-name">{institute_name}</h4>
							<p>{stream_branch}</p>
						</Cell>

					</Grid>
					))}
					<hr className="resume-right-section-border" />
					<h2>Skills</h2>
					
					{skills.map(({ _id, skill_name, skill_progress, skill_buffer}) => (
						<Grid key={_id} timeout={100} classnames="fade">
							{ this.props.isAuthenticated && (user.is_admin === true) ? 
							<Button className="remove-btn"
							color="danger"
							size="sm"
							onClick= {this.onDeleteSkillsClick.bind(this, _id)}>
								&times;
							</Button> : null }
							<Cell col={12}>
								<div style={{display: 'flex'}}>
									{skill_name}<ProgressBar progress={skill_progress} buffer = { skill_buffer} style={{margin: 'auto', width: '75%'}} />
								</div>
							</Cell>
						</Grid>
					))}
				</Cell>
				</Grid>
				<FooterBody/>
			</div>
			)}
		</Container>
		)
	}
}

const mapStateToProps = (state) => ({
    resume: state.resume,
    isAuthenticated : state.auth.isAuthenticated,
	auth: state.auth,
	loading: state.apiCallsInProgress > 0
});

export default connect(mapStateToProps, { getResume, deleteResume, 
	getWorkexperience, deleteWorkexperiences, 
	getInstitutename, deleteInstitutename,
	getSkill, deleteSkill }) (Resume);