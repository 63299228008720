import React from 'react';
import { Grid, Cell, Dialog, CardMenu, CardTitle, CardText, CardActions, FABButton, Icon} from'react-mdl';
import { connect } from 'react-redux';
import { getBlog} from '../../actions/resumeActions';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    EmailIcon,
    FacebookShareCount
  } from 'react-share';
import { Container } from "reactstrap";
const BlogData = ({ blogs, toggle, handleCloseDialog, ...state}) => {
    const itemsPerPage = 6; 
    let activeBlogs = blogs.slice (itemsPerPage * state.activePage - itemsPerPage, itemsPerPage * state.activePage);
    const shareUrl = "https://tanmoysarkar.dev/#/blog";
    const tagsBlog = "#tammysblog";
    return( 
        <Grid style={{padding: 0, display: 'contents'}}>

            {activeBlogs.map(({ _id, blog_name, blog_short_desc, blog_desc_head1, blog_desc_text1 ,
            blog_desc_head2, blog_desc_text2, blog_desc_head3, blog_desc_text3, blog_desc_head4, blog_desc_text4, blog_desc_head5, blog_desc_text5, blog_image_link, blog_by_author }) => (
            <Cell col={12}>
                <Dialog open={state.openDialog && state.justClicked === _id} className="open-dialog">

                    {blog_image_link ?
                        (<CardTitle style={{color: '#fff', height: '176px', backgroundImage: `url(${blog_image_link})`, backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'}} className="card-blog-title-image-text">{blog_name}</CardTitle>) :

                        (<CardTitle className="card-blog-title-image">{blog_name}</CardTitle>
                        )
                    }
                    
                    
                    <CardText className="blog-data-text">
                        <h5 className="h4-blog-data-text">{blog_desc_head1}</h5>
                        {blog_desc_text1}
                    </CardText>
                    
                    <CardText className="blog-data-text">
                        <h5 className="h4-blog-data-text">{blog_desc_head2}</h5>
                        {blog_desc_text2}
                    </CardText>
                    
                    <CardText className="blog-data-text">
                        <h5 className="h4-blog-data-text">{blog_desc_head3}</h5>
                        {blog_desc_text3}
                    </CardText>
                    
                    <CardText className="blog-data-text">
                        <h5 className="h4-blog-data-text">{blog_desc_head4}</h5>
                        {blog_desc_text4}
                    </CardText>
                    
                    <CardText className="blog-data-text">
                        <h5 className="h4-blog-data-text">{blog_desc_head5}</h5>
                        {blog_desc_text5}
                    </CardText>
                    <CardActions border>
                       
                        <p style={{float:'right', fontWeight:'bold'}}>Author: {blog_by_author}</p>
                    </CardActions>
                    <CardMenu className="blog-data-share-social-cardmenu">
                        <Container>
                            <FacebookShareButton
                                url= {shareUrl}
                                quote={blog_name}
                                hashtag ={tagsBlog}
                                className="ml-2"
                                
                            >
                                <FacebookIcon size={28} round={true} />
                                <FacebookShareCount url={shareUrl}>
                                {shareCount => <span className="myShareCountWrapper">{shareCount}</span>}
                                </FacebookShareCount>
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={shareUrl}
                                title ={blog_name}
                                hashtag ={tagsBlog}
                                className="ml-2"
                                
                            >
                                <TwitterIcon size={28} round={true} />
                            </TwitterShareButton>
                            <LinkedinShareButton
                                url={shareUrl}
                                title ={blog_name}
                                summary  = {blog_short_desc}
                                source = {shareUrl}
                                className="ml-2"
                            >
                                <LinkedinIcon size={28} round={true} />
                            </LinkedinShareButton>
                            <WhatsappShareButton
                                url={shareUrl}
                                title ={blog_name}
                                className="ml-2"
                            >
                                <WhatsappIcon size={28} round={true} />
                            </WhatsappShareButton>
                            <EmailShareButton
                                url={shareUrl}
                                subject  ={blog_name}
                                body = {blog_short_desc}
                                className="ml-2"
                            >
                                <EmailIcon size={28} round={true} />
                            </EmailShareButton> 
                        
                        </Container>
                    </CardMenu>
                    <CardMenu style={{color: '#fff'}}>
                        
                        <FABButton onClick={handleCloseDialog} className="close-button" >
                            <Icon name="close" />
                        </FABButton>
                    </CardMenu>
                </Dialog>
            </Cell>
            ))}
        </Grid>
        
    )
}

const mapStateToProps = state => ({
    resume: state.resume,
    auth: state.auth
})

export default connect(mapStateToProps, { getBlog })(BlogData);