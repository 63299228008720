import axios  from 'axios';
import * as types from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';

// contacts

export const getContact = () => dispatch => {
    dispatch(setResumesLoading());
    axios
        .get('/api/contacts')
        .then(res => 
            dispatch({
                type: types.GET_CONTACT,
                payload: res.data
            })
        ).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const addContact = contact => (dispatch, getState) => {
   axios
   .post('/api/contacts', contact, tokenConfig(getState))
   .then(res => 
    dispatch({
        type: types.ADD_CONTACT,
        payload: res.data
    })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const deleteContact = id => (dispatch, getState) => {
    axios
    .delete(`/api/contacts/${id}`, tokenConfig(getState)).then(res => 
        dispatch({
            type: types.DELETE_CONTACT,
            payload: id

        })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

// Education

export const getInstitutename = () => dispatch => {
    dispatch(setResumesLoading());
    axios
        .get('/api/educations')
        .then(res => 
            dispatch({
                type: types.GET_INSTITUTE_NAME,
                payload: res.data
            })
        ).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const addInstitutename = education => (dispatch, getState) => {
   axios
   .post('/api/educations', education, tokenConfig(getState))
   .then(res => 
    dispatch({
        type: types.ADD_INSTITUTE_NAME,
        payload: res.data
    })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const deleteInstitutename = id => (dispatch, getState) => {
    axios
    .delete(`/api/educations/${id}`, tokenConfig(getState)).then(res => 
        dispatch({
            type: types.DELETE_INSTITUTE_NAME,
            payload: id

        })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};


// Project

export const getProject = () => dispatch => {
    dispatch(setResumesLoading());
    axios
        .get('/api/projects')
        .then(res => 
            dispatch({
                type: types.GET_PROJECT,
                payload: res.data
            })
        ).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const addProject = project => (dispatch, getState) => {
   axios
   .post('/api/projects', project, tokenConfig(getState))
   .then(res => 
    dispatch({
        type: types.ADD_PROJECT,
        payload: res.data
    })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const deleteProject = id => (dispatch, getState) => {
    axios
    .delete(`/api/projects/${id}`, tokenConfig(getState)).then(res => 
        dispatch({
            type: types.DELETE_PROJECT,
            payload: id

        })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

// Resume

export const getResume = () => dispatch => {
    dispatch(setResumesLoading());
    axios
        .get('/api/resumes')
        .then(res => 
            dispatch({
                type: types.GET_RESUME,
                payload: res.data
            })
        ).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const addResume = resume => (dispatch, getState) => {
   axios
   .post('/api/resumes', resume, tokenConfig(getState))
   .then(res => 
    dispatch({
        type: types.ADD_RESUME,
        payload: res.data
    })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const deleteResume = id => (dispatch, getState) => {
    axios
    .delete(`/api/resumes/${id}`, tokenConfig(getState)).then(res => 
        dispatch({
            type: types.DELETE_RESUME,
            payload: id

        })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

// Skill

export const getSkill = () => dispatch => {
    dispatch(setResumesLoading());
    axios
        .get('/api/skills')
        .then(res => 
            dispatch({
                type: types.GET_SKILLS,
                payload: res.data
            })
        ).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const addSkill = skill => (dispatch, getState) => {
   axios
   .post('/api/skills', skill, tokenConfig(getState))
   .then(res => 
    dispatch({
        type: types.ADD_SKILLS,
        payload: res.data
    })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const deleteSkill = id => (dispatch, getState) => {
    axios
    .delete(`/api/skills/${id}`, tokenConfig(getState)).then(res => 
        dispatch({
            type: types.DELETE_SKILLS,
            payload: id

        })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

// workexperience

export const getWorkexperience = () => dispatch => {
    dispatch(setResumesLoading());
    axios
        .get('/api/workexperiences')
        .then(res => 
            dispatch({
                type: types.GET_WORKEXPERIENCE,
                payload: res.data
            })
        ).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const addWorkexperience = workexperience => (dispatch, getState) => {
   axios
   .post('/api/workexperiences', workexperience, tokenConfig(getState))
   .then(res => 
    dispatch({
        type: types.ADD_WORKEXPERIENCE,
        payload: res.data
    })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const deleteWorkexperiences = id => (dispatch, getState) => {
    axios
    .delete(`/api/workexperiences/${id}`, tokenConfig(getState)).then(res => 
        dispatch({
            type: types.DELETE_WORKEXPERIENCE,
            payload: id

        })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

// blogs

export const getBlog = () => dispatch => {
    dispatch(setResumesLoading());
    axios
        .get('/api/blogs')
        .then(res => 
            dispatch({
                type: types.GET_BLOG,
                payload: res.data
            })
        ).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const addBlog = blog => (dispatch, getState) => {
   axios
   .post('/api/blogs', blog, tokenConfig(getState))
   .then(res => 
    dispatch({
        type: types.ADD_BLOG,
        payload: res.data
    })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const editBlog = id => (dispatch, getState) => {
    axios
    .post(`/api/blogs/edit/${id}`, tokenConfig(getState)).then(res => 
        dispatch({
            type: types.EDIT_BLOG,
            payload: res.data

        })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const updateBlog = (id, blog) => (dispatch, getState) => {
    axios
    .post(`/api/blogs/update/${id}`, blog, tokenConfig(getState))
    .then(res => 
     dispatch({
         type: types.UPDATE_BLOG,
         payload: res.data
     })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
 };

export const deleteBlog = id => (dispatch, getState) => {
    axios
    .delete(`/api/blogs/${id}`, tokenConfig(getState)).then(res => 
        dispatch({
            type: types.DELETE_BLOG,
            payload: id

        })).catch (err => dispatch (returnErrors(err.response.data, err.response.status)));
};

export const setResumesLoading = () => {
    return {
        type: types.RESUMES_LOADING
    };
};
