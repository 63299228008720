import React, {Component} from 'react';
import {Tabs, Tab, Grid, Cell, Card, CardTitle, CardText, CardActions, Button} from 'react-mdl';
import { connect } from 'react-redux';
import { getProject, deleteProject} from '../../actions/resumeActions';
import PropTypes from 'prop-types';
import { Container } from "reactstrap";
import Loading from './Loading';
import FooterBody from "./Footer";
class Projects extends Component{
	constructor(props){
		super(props);
		this.state={
      activeTab: 0
    };
  }
    
  static propTypes = {
    getProject: PropTypes.func.isRequired,
    deleteProject: PropTypes.func.isRequired,
    resume: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool,
    auth: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired
  }

  componentDidMount() {
  this.props.getProject();
  }

  onDeleteProjectClick = (id) => {
      this.props.deleteProject(id);
  };

	toggleCategories(projects, user) {
        return projects
          .filter(project =>
            this.state.activeTab === 0
              ? project.project_type === "Reactjs"
              : project.project_type === "Html"
          )
          .map(
            ({
              _id,
              project_name,
              project_description,
              project_link,
              project_image_link
            }) => (
              <Grid  className="projects-grid">
                
                <Card key={_id} timeout={100} shadow={5} className="cards-grid">
                { this.props.isAuthenticated && (user.is_admin === true) ? 
                    <Button className="remove-btn"
                    color="danger"
                    size="sm"
                    onClick= {this.onDeleteProjectClick.bind(this, _id)}>
                        &times;
                    </Button> : null }
                    
                  <CardTitle
                    style={{color:'#fff', height:'185px', backgroundImage: `url(${project_image_link})`, backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'}}
                  >
                    {project_name}
                  </CardTitle>
                  <CardText>{project_description}</CardText>
                  <CardActions border>

                  <Button className="project-link-button col-1">
                    <a href={project_link} target="_blank" rel="noopener noreferrer">
                      Link
                    </a>  
                  </Button> 
                  </CardActions>
                </Card>
              </Grid>
            )
          );
      }
	
	render(){
    const { projects, loading} = this.props.resume;
    const { user } = this.props.auth;
		return(
      <Container>
        {loading ? (
	   		<div><Loading/></div>) : 
	   		( <div className="category-tabs">   
            <Tabs activeTab ={this.state.activeTab} onChange={(tabId) => this.setState({activeTab: tabId})} ripple>

            <Tab> React/Node </Tab>
            <Tab> HTML/CSS/JS </Tab>
            
            </Tabs>
            
            <Grid >
                <Cell col={12}>
                    {this.toggleCategories(projects, user)}
                </Cell>
            </Grid>
            <FooterBody/>  
          </div> )}
      </Container>
			
		)
	}
}


const mapStateToProps = (state) => ({
    resume: state.resume,
    isAuthenticated : state.auth.isAuthenticated,
    auth: state.auth,
    loading: state.apiCallsInProgress > 0
});

export default connect(mapStateToProps, {getProject, deleteProject }) (Projects);