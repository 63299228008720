import React, {Component} from 'react';
import {Grid, Cell} from 'react-mdl';
import { Container} from 'reactstrap';
import FooterBody from "./Footer";
class Aboutme extends Component{
	render(){
		return(
			<Container>
			<Grid  className="about-header">
				<Cell col={6} className="about-img" style={{background: 'url(https://res.cloudinary.com/tammycloudinary/image/upload/v1547241626/about.gif) center / cover'}}>
				</Cell>
				
				<Cell col={6} >
					<div className="about-details">
						<h4>About Me</h4>

						<p>Hi, I'm Tanmoy Sarkar. I'm a Software Developer. I love pets specially Puppy's. I enjoy exploring new places, taking photography, meeting new people and learning their culture and moreover I share about anything that motivates people for living a healthy life and last but not least I love spending time with my family.</p>

						<hr className="about-section-border" />

						<h4>Summary Qualification</h4>
						<ul>
							<li>Experienced at full stack javascript framework such as Reactjs, JavaScript, Boorstrap, SCSS, MongoDB </li>
							<li>Experienced at DevOps related works</li>
							<li>Experienced with WebRTC/MediaSDK </li>
							<li>Experience as a developer, designer has resulted in exceptional problem solving skills, critical thinking skills, and patience.</li>
							<li>Understanding of SEO and accessibility issues  as they relate to the front-end.</li>
						</ul>
						<hr className="about-section-border" />

						<h4>Languages</h4>
						<ul>
							<li>Native</li>
							<ul>
								<li>Bengali</li>
							</ul>
						</ul>
						<ul>
							<li>Fluent</li>
							<ul>
								<li>English</li>
								<li>Hindi</li>
							</ul>
						</ul>
						
						<hr className="about-section-border"/>

						<h4>UI web technologies/libraries</h4>
						<p>ReactJs, Redux, Javascript, jQuery, JSON, CSS3, HTML5, Bootstrap </p>
						
						<hr className="about-section-border" />

						<h4>Frameworks</h4>
						<ul>
							<li>Front-end: ReactJs, React-Native, Bootstrap</li>
							<li>Back-end: Node.js, Express.js</li>
							<li>Database: MongoDB, Mysql</li>
						</ul>
						
						<hr className="about-section-border"/>

						<h4>Softwares / Tools:</h4>
						<p>Git, GitHub, Bitbucket, Slack</p>

						<hr className="about-section-border"/>

						<h4>Methodologies</h4>
						<p>SAFe methodologies </p>

						<hr className="about-section-border" />

						<h4>IDE/Editors</h4>
						<p>Microsoft Visual Studio, Notepad++, Sublime</p>
						
						<hr className="about-section-border" />

						<h4>Operating Systems</h4>
						<p>Linux(Ubuntu), Windows(10)</p>
						
					</div>
				</Cell>
			</Grid> 
			 <FooterBody/>
			</Container>

		)
	}
}

export default Aboutme;