import React, {Component} from 'react';
import {Card, Grid, Cell, Button, CardTitle, CardText, CardActions } from'react-mdl';
import { Container} from 'reactstrap';
import { connect } from 'react-redux';
import { getBlog, deleteBlog, updateBlog } from '../../actions/resumeActions';
import PropTypes from 'prop-types';
import Loading from './Loading';
import Moment from 'moment';
import BlogModal from "./BlogModal";
import Pagination from "react-js-pagination";
import EditBlog from "./EditBlog";
import BlogData from "./BlogData";
import FooterBody from "./Footer";

class Blogs extends Component{
    constructor(props) {
        super(props);
            this.state = {
                modal: false,
                justClicked: null,
                activePage: 1,
                requiredItem : null,
                _id: '',
                blog_short_desc:'',
                blog_name: '',
                blog_desc_head1: '',
                blog_desc_text1: '',
                blog_desc_head2: '',
                blog_desc_text2: '',
                blog_desc_head3: '',
                blog_desc_text3: '',
                blog_desc_head4: '',
                blog_desc_text4: '',
                blog_desc_head5: '',
                blog_desc_text5: '',
                blog_image_link: '',
                blog_by: '',
                blog_by_author: ''
            };
            this.handleOpenDialog = this.handleOpenDialog.bind(this);
            this.handleCloseDialog = this.handleCloseDialog.bind(this);
            this.replaceModalItem = this.replaceModalItem.bind(this);
            this.onTodoChange = this.onTodoChange.bind(this);
        }

    static propTypes = {
        getBlog: PropTypes.func.isRequired,
        deleteBlog: PropTypes.func.isRequired,
        updateBlog: PropTypes.func.isRequired,
        resume: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        loading: PropTypes.object.isRequired
    }

    toggle = (id) => {
        this.setState({
            modal: !this.state.modal
        });
    }

    componentDidMount() {
        this.props.getBlog();  
    }

    replaceModalItem(id, blog_short_desc, blog_name , blog_desc_head1, blog_desc_text1, 
         blog_desc_head2, blog_desc_text2,  blog_desc_head3, blog_desc_text3, 
         blog_desc_head4, blog_desc_text4, blog_desc_head5, blog_desc_text5,
          blog_image_link, blog_by, blog_by_author) {
        this.setState({
          modal: true,
          requiredItem: id,
          _id: id,
          blog_short_desc: blog_short_desc,
          blog_name: blog_name,
          blog_desc_head1: blog_desc_head1,
          blog_desc_text1: blog_desc_text1,
          blog_desc_head2: blog_desc_head2,
          blog_desc_text2: blog_desc_text2,
          blog_desc_head3: blog_desc_head3,
          blog_desc_text3: blog_desc_text3,
          blog_desc_head4: blog_desc_head4,
          blog_desc_text4: blog_desc_text4,
          blog_desc_head5: blog_desc_head5,
          blog_desc_text5: blog_desc_text5,
          blog_image_link: blog_image_link,
          blog_by: blog_by,
          blog_by_author: blog_by_author
        });
    }

    onTodoChange = (e) => {
        this.setState({ 
            [e.target.name] : e.target.value 
        });
    }

    onSubmit = (e, id) => {
        
        e.preventDefault();
        const updatedBlog = {
            blog_short_desc: this.state.blog_short_desc,
            blog_name: this.state.blog_name,
            blog_desc_head1: this.state.blog_desc_head1,
            blog_desc_text1: this.state.blog_desc_text1,
            blog_desc_head2: this.state.blog_desc_head2,
            blog_desc_text2: this.state.blog_desc_text2,
            blog_desc_head3: this.state.blog_desc_head3,
            blog_desc_text3: this.state.blog_desc_text3,
            blog_desc_head4: this.state.blog_desc_head4,
            blog_desc_text4: this.state.blog_desc_text4,
            blog_desc_head5: this.state.blog_desc_head5,
            blog_desc_text5: this.state.blog_desc_text5,
            blog_image_link: this.state.blog_image_link,
            blog_by:  this.props.auth["user"]._id,
            blog_by_author: this.props.auth["user"].name
        }
       
        //update blog via updateblog action
        this.props.updateBlog(id, updatedBlog);
        alert("Blog updated successfully!");
        e.target.reset();
        this.toggle();
        window.location.reload();
    }

    handleOpenDialog(id) {
        this.setState({
          openDialog: true,
          OpenEditDialog: true,
          justClicked: id

        });
    }

    handleCloseDialog() {
    this.setState({
        openDialog: false
    });
    }

    onDeleteBlogClick = (id) => {
        this.props.deleteBlog(id);
    };

    handlePageChange(pageNumber) {
        this.setState({activePage: pageNumber});
    }

    render(){
        const { blogs, loading} = this.props.resume;
        const {  user, isAuthenticated } = this.props.auth;
        const itemsPerPage = 6; 
        let activeBlogs = blogs.slice (itemsPerPage * this.state.activePage - itemsPerPage, itemsPerPage * this.state.activePage);
        return(
            <Container>
            {loading ? (
            <div><Loading/></div>
            ) : (
                <div>
                    {/* blog modal */}
                    <BlogModal />

                    {/* card dialog */}
                    <BlogData blogs={blogs} user={this.props.auth} handleCloseDialog={this.handleCloseDialog}  {...this.state} toggle={this.toggle}/>

                    {/* edit card dialog */}
                    <EditBlog onTodoChange={this.onTodoChange}  {...this.state} toggle={this.toggle} onSubmit={this.onSubmit}/>
                    <h3 className="heading-page-name">Blogs</h3>
                        <hr class="hr-section-border"/>
                    <Grid style={{padding: 0}}  className="blog-grid">
                        
                        {activeBlogs.map((item, i) => (
                            <Cell key={item._id} data-id={item._id} className="blog-grid-cell">   
                                <Card shadow={5} className="cards-grid">
                                    {item.blog_image_link ?
                                        (<CardTitle style={{color: '#fff', height: '16rem',
                                        width: 'auto', backgroundImage: `url(${item.blog_image_link})`, backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'}}></CardTitle>) :

                                        (<CardTitle className="card-title-image"></CardTitle>
                                        )
                                    }

                                    <CardText>
                                        <b>{item.blog_short_desc}</b>
                                    </CardText>

                                    <CardActions border>
                                        <p className="block-data-details">
                                            <Button className="blog-read-me-button col-4" onClick={this.handleOpenDialog.bind(this, item._id)}>Read </Button> 

                                            { isAuthenticated === true && (item.blog_by === user._id) ? 
                                            <span className="col=8">

                                            <Button className="remove-btn-blog-post"
                                            color="danger"
                                            size="sm"
                                            onClick= {this.onDeleteBlogClick.bind(this, item._id)} title="Delete Blog">
                                                &times;
                                            </Button> 
                                                {/* eslint-disable-next-line */}
                                            <a className="btn edit-btn-blog-post"  
                                            onClick={this.replaceModalItem.bind(this, item._id, item.blog_short_desc, item.blog_name, item.blog_desc_head1, item.blog_desc_text1,
                                                item.blog_desc_head2, item.blog_desc_text2, item.blog_desc_head3, item.blog_desc_text3,
                                                item.blog_desc_head4, item.blog_desc_text4, item.blog_desc_head5, item.blog_desc_text5, item.blog_image_link, item.blog_by, item.blog_by_author )}  title="Edit Blog">
                                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                            </a>
                                            </span> : null }
                                        </p>
                                        <p style={{ fontWeight:'bold'}}>By-{item.blog_by_author} <span style={{float:'right',}}>{Moment(item.date).format('Do MMMM YYYY')}</span></p> 
                                    </CardActions>
                                </Card>
                                
                            </Cell> 
                             
                        ))} 
                    </Grid>
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={6}
                        totalItemsCount={blogs.length}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange.bind(this)}
                        itemClass='page-item'
                        linkClass='page-link'
                        /> 
                     <FooterBody/>
                </div>
               
                )}
                
            </Container>
        ) 
   }
}

const mapStateToProps = (state) => ({
    resume: state.resume,
    auth: state.auth,
    loading: state.apiCallsInProgress > 0
});

export default connect(mapStateToProps, {getBlog, deleteBlog, updateBlog }) (Blogs);